import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Server.css";
const EditServer = () => {
  const [server, setServer] = useState([]);
  const { id } = useParams();
  const getServerApi = "http://localhost:3000/server";

  useEffect(() => {
    getServer();
  }, []);

  const getServer = () => {
    axios
      .get(getServerApi.concat("/") + id)
      .then((item) => {
        setServer(item.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="server mt-5">
      <table className="table table-bordered">
    <thead>
      <tr>
        <th>Field</th>
        <th>Value</th>
        
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Name</td>
        <td>{server.name}</td>
      </tr>
      <tr>
        <td>Owner</td>
        <td>{server.owner}</td>
      </tr>
      <tr>
        <td>Icon</td>
        <td>{server.icon}</td>
      </tr>
    </tbody>
  </table>
    </div>
  );
};
export default EditServer;

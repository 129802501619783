import React from 'react'
import Carasol from '../Pages/Carosol'
const Home = ()=> {
  return (
    <div>
        <Carasol />
    </div>
  )
}

export default Home
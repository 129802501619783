import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Common/Loader";
import "./Server.css";
const CreateServer = () => {
  const navigate = useNavigate();
  const createServerApi = "http://localhost:3000/server";
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [server, setServer] = useState({
    id: "",
    name: "",
    owner: "",
    icon: "",
  });

  const handelInput = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    console.log(name, value);
    setServer({ ...server, [name]: value });
  };

  const handelSubmit = async (event) => {
    event.preventDefault();
    console.log(server);
    try {
      setIsLoading(true);
      const response = await fetch(createServerApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(server),
      });

      if (response.ok) {
        console.log("Form submitted successfully!");
        setServer({ id: "", name: "", owner: "", icon: "" });
        navigate("/show-server");
      } else {
        console.error("Form submission failed!");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="server-form">
      <div className="heading">
        {isLoading && <Loader />}
        {error && <p>Error: {error}</p>}
        <p>Server Form</p>
      </div>
      <form onSubmit={handelSubmit}>
        <div className="mb-3">
          <label for="id" className="form-label">
            ID
          </label>
          <input
            type="text"
            className="form-control"
            id="id"
            name="id"
            value={server.id}
            onChange={handelInput}
          />
        </div>
        <div className="mb-3">
          <label for="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={server.name}
            onChange={handelInput}
          />
        </div>
        <div className="mb-3 mt-3">
          <label for="owner" className="form-label">
            Owner
          </label>
          <input
            type="text"
            className="form-control"
            id="owner"
            name="owner"
            value={server.owner}
            onChange={handelInput}
          />
        </div>
        <div className="mb-3">
          <label for="icon" className="form-label">
            Icon
          </label>
          <input
            type="text"
            className="form-control"
            id="icon"
            name="icon"
            value={server.icon}
            onChange={handelInput}
          />
        </div>
        <button type="submit" className="btn btn-primary submit-btn">
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateServer;

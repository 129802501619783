import "./App.css";
import CreateServer from "./components/Server/CreateServer";
import ShowServer from "./components/Server/ShowServer";
import { Route, Routes } from "react-router-dom";
import EditServer from "./components/Server/EditServer";
import Server from "./components/Server/Server";
import Header from "./components/Common/Header";
import Home from "./components/Layout/Home";
function App() {
  return (
    <div className="App">
      <header className="container">
        <div className="">
          <Header />
          <Routes>
          
            <Route path="/" element={<Home />} />
            <Route path="/edit-server/:id" element={<EditServer />} />
            <Route path="/server/:id" element={<Server />} />
            <Route path="/create-server" element={<CreateServer />} />
            <Route path="/show-server" element={<ShowServer />} />
          </Routes>
          
        </div>
      </header>
    </div>
  );
}

export default App;
